<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-21">
        <div class="col-md-12">
           <div class="form-group">
            <label>Announcement</label>
            <b-form-textarea
                v-model="form.announcement"
                placeholder="This text will appear once the customer will open order page"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            <span class="form-text text-muted">This text will appear once the customer will open order page</span>
          </div>
        </div>
        <!-- <div class="col-md-12">
           <div class="form-group">
            <label>In-Menu Announcement</label>
            <b-form-textarea
                v-model="form.menu_announcement"
                placeholder="Message or Promotions to show above the menu"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            <span class="form-text text-muted">Message or Promotions to show above the menu.</span>
          </div>
        </div> -->
        <div class="col-md-12">
          <button    v-if=" $hasPermission('manage business settings') "
          class="btn btn-primary font-weight-bold  px-9 py-4" v-on:click="save">Update Announcement</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_GENERAL_SETTINGS, GENERAL_SETTINGS } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
export default {
  name: "Announcement",
  data() {
    return {
      form : {
        announcement : "",
        menu_announcement : ""
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Announcement" },
    ]);

    this.getGeneralSettings();
  },
  methods: {
    getGeneralSettings() {
      this.$store
        .dispatch(GET_GENERAL_SETTINGS)
        // go to which page after successfully login
        .then((resp) => {
        for(let i = 0 ; i < resp.length; i ++)
        {
          if(resp[i].key == "announcement")
          {
            this.form.announcement = resp[i].value
          }
          if(resp[i].key == "menu_announcement")
          {
            this.form.menu_announcement = resp[i].value
          }
        }
        // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
        if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
        }
        });
    },
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          announcement : this.form.announcement,
          menu_announcement : this.form.menu_announcement
        })
        // go to which page after successfully login
        .then((resp) => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            this.getGeneralSettings()
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
  },
};
</script>
